<template>
  <div id="app">
    <div class="body fixed mobile">
      <!-- class: fixed, mobile -->
      <!-- Loading Spinner -->
      <!-- <div class="spinner"><div><div></div></div></div> -->
      <!-- Header -->
      <section class="header point_history">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
        </a>
        <span class="fL">交易紀錄</span>
        <div class="icon iconButton"></div>
      </section>
      <div class="uide-tabs main-top">
        <a class="uide-tab" :class="{ active: filterByType === 'D' && listMode=== 'record' }" @click.prevent="listMode = 'record', changeTypeFilter('D')">
          <span class="fM">獲得紀錄</span>
        </a>
        <a class="uide-tab" :class="{ active: filterByType === 'C' && listMode=== 'record' }" @click.prevent="listMode = 'record', changeTypeFilter('C')">
          <span class="fM">兌換紀錄</span>
        </a>
        <a class="uide-tab" :class="{ active: listMode=== 'expire' }" @click.prevent="listMode = 'expire'">
          <span class="fM">點數到期日</span>
        </a>
      </div>
      <div class="uide-header-bottom-wrap">

        <div class="uide-filter-wrap" @click.stop v-if="listMode === 'record'">
          <a class="uide-filter" @click.prevent="timeFilterPopOpen = true">
            <span class="">{{ displayedTimeFilterOption }}</span>
            <div class="icon ">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a>
          <!-- <a class="uide-filter" @click.prevent="typeFilterPopOpen = true">
            <span class="fM">{{ typeFilterOptions[filterByType] }}</span>
            <div class="icon ">
              <font-awesome-icon icon="fa-solid fa-caret-down" size="xs" />
            </div>
          </a> -->
        </div>
      </div>
      <!-- Main -->
      <section class="main point_history no-top mt-2">

        <!-- 紀錄 -->
        <div class="records flexV width padding gap-4">
          <div class="text-center flex gap-6 item-center py-4 flex-col"
            v-if="(listMode === 'record' && (!transactions.records || transactions.records.length == 0)) || (listMode === 'expire' && pointPeriods.length == 0)">
            <img width="160" height="160" src="@/assets/icon/page-empty-content.svg" />
            <div class="fL font-medium  cNeutral500 text-center">您目前尚未有獲得紀錄</div>
          </div>
          <!-- arnoldtodo 需復原 -->
          <!-- <div v-for="(transaction, index) in displayTransactions" :key="'transaction' + index">
            <div class="record flexH width between margin">
              <div class="flexV">
                <span class="fL">{{ $t(transaction.transactionType) }}</span>
                <span class="fM gray">{{ transaction.note }}</span>
              </div>
              <div class="flexV end">
                <div class="flexH">          
                  <span :class="pointClass(transaction.entryType)">{{ displaySignOfPoint(transaction.entryType)
                  }}{{ transaction.pointAmount }}</span>
                </div>
                <span class="fM gray">{{
                  tsToDatetime(transaction.transactionTs)
                }}</span>
              </div>
            </div>
            <div class="lineH"></div>
          </div> -->
          <!-- arnold-todo 只作樣板 -->
           <template v-if="listMode === 'record'">
             <div class="flex flex-row bg-white py-4 px-4 rounded-2xl" v-for="rec in transactions.records" :key="rec.transactionId" @click.prevent="$router.push('/record-detail/' + rec.transactionId)">
               <div class="flex-7 flex flex-col gap-1">
                 <div class="text-lg text-c03">
                   {{rec.note}}
                 </div>
                 <div class="cNeutral500">
                   {{tsToDatetime(rec.transactionTs)}}
                 </div>
               </div>
               <div class="flex-3 flex justify-right">
                 <img width="24px" src="@/assets/icon/uide/chevron-01.svg" />
               </div>
             </div>
           </template>
           <template v-else>
            <div class="records flexV width padding">
                    <div
                        class="record flexH width between margin padding"
                        v-for="(pointPeriod, index) in pointPeriods"
                        :key="'point' + index"
                    >
                        <span class="fL"
                            >{{ tsToDate(pointPeriod.endTs) }}到期</span
                        >
                        <div class="flexH">
                            <!-- <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div> -->
                            <span class="fL bold">{{
                                pointPeriod.pointBalance
                            }}枚</span>
                        </div>
                    </div>
                    <div class="lineH"></div>
                </div>
           </template>
        </div>
      </section>
      <section class="footer point_history">
        <a class="button rounded" @click.prevent="$router.push('/scanner')">
          <div class="icon">
            <img src="@/assets/icon/scanner.svg" />
          </div>
          <span class="fM">使用紅利點數</span>
        </a>
      </section>
      <!-- Popup (時間區間) -->
      <FilterPopup v-if="timeFilterPopOpen" @closePopup="timeFilterPopOpen = false" v-slot="pop" :popTime="true">
        <section class="popHeader">
          <span class="fL">時間區間</span>
        </section>
        <section class="popMain">
          <!-- Tabs -->
          <div class="tabs rounded fS">
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Weekly' },
            ]" @click.prevent="changeTimeFilterOption('Weekly')">
              <span>近一週</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Monthly' },
            ]" @click.prevent="changeTimeFilterOption('Monthly')">
              <span>近一個月</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Yearly' },
            ]" @click.prevent="changeTimeFilterOption('Yearly')">
              <span>近一年</span>
            </a>
            <a :class="[
              'tab',
              'rounded',
              { active: timeFilterOption === 'Customize' },
            ]" @click.prevent="changeTimeFilterOption('Customize')">
              <span>自訂時間</span>
            </a>
          </div>
          <!-- Date -->
          <div class="input rounded" v-show="timeFilterOption == 'Customize'">
            <!-- class: fake / error -->
            <input :class="['fM', 'date', { error: selectStartErr }]" type="date" name="startDate" v-model="selectStart"
              @change="selectStartChange" />
            <!-- <input class="fM time" type="time" name="startTime"> -->
            <span class="fL">-</span>
            <input :class="['fM', 'date', { error: selectEndErr }]" type="date" name="endDate" v-model="selectEnd"
              @change="selectEndChange" />
          </div>
        </section>
        <section class="popFooter">
          <a class="button rounded cancel white" @click.prevent="pop.close"><span class="fM">取消</span></a>
          <a class="button rounded submit" @click.prevent="
            timeFilterConfirm();
          pop.close();
          "><span class="fM">確認</span></a>
        </section>
      </FilterPopup>
      <!-- Popup (下拉選單) -->
      <FilterPopup v-if="typeFilterPopOpen" @closePopup="typeFilterPopOpen = false" v-slot="pop">
        <section class="popHeader">
          <span class="fL">類型</span>
        </section>
        <section class="popMain" @click.prevent="pop.close">
          <a v-for="(name, type) in typeFilterOptions" :key="'type_' + type" :class="{ active: filterByType == type }"
            @click.prevent="changeTypeFilter(type)"><span>{{ name }}</span></a>
        </section>
      </FilterPopup>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapGetters } from 'vuex';
import FilterPopup from '@/components/FilterPopup.vue';

export default {
  name: 'Record',
  components: { FilterPopup },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      transactions: {},
      filterByType: 'D',
      originTransactions: {},
      timeFilterStart: null,
      timeFilterEnd: null,
      timeFilterOption: 'Monthly',
      displayedTimeFilterOption: '近一個月',
      selectStart: this.tsToDate(
        Math.floor(Date.now() / 1000) - 1 * 60 * 60 * 24 * 30
      ),
      selectEnd: this.tsToDate(Math.floor(Date.now() / 1000)),
      selectStartErr: false,
      selectEndErr: false,
      typeFilterOptions: {
        A: '全部類型',
        D: '獲得點數',
        C: '點數消費',
      },
      timeFilterPopOpen: false,
      typeFilterPopOpen: false,
      listMode: 'record', // record / expire
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    if (from.path === '/profile') {
      to.meta.parent = '/profile';
    } else if (
      from.path === '/' ||
      from.path === '/record' ||
      from.path === '/point-list' ||
      from.path === '/payment-record'
    ) {
      to.meta.parent = localStorage.getItem('parentPath') || '/home';
    } else {
      to.meta.parent = '/home';
    }
    next((vm) => {
      let that = vm;
      // console.log('user ', that.user);
      if (that.user && that.user.userId) {
        //
      } else {
        next('/error');
      }
    });
  },
  created() {
    this.getUserPoints()
      .then((getUserPointsRes) => {
        console.log('getUserPointsRes: ', getUserPointsRes);
        this.$store.commit('updateUserPoints', getUserPointsRes);
        return this.getTransactions();
      })
      .then((getTransactionsRes) => {
        this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
        this.originTransactions = JSON.parse(
          JSON.stringify(getTransactionsRes)
        );
      })
      .catch((err) => {
        console.log('err: ', err);
        this.router.push('/error');
      });
  },
  mounted() { },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      userPoints: 'getTyUserPoints',
    }),
    pointPeriods() {
      return this.userPoints.pointPeriods;
    },
    displayLastestEndDateOfPoints() {
      return this.lastestEndOfPoint
        ? this.tsToDate(this.lastestEndOfPoint.endTs)
        : '';

      // if (
      //     this.userPoints &&
      //     this.userPoints.pointPeriods &&
      //     this.userPoints.pointPeriods.length > 0
      // ) {
      //     return this.tsToDate(this.userPoints.pointPeriods[0].endTs);
      // } else {
      //     return "";
      // }
    },
    displayLastestBalanceOfPoints() {
      return this.lastestEndOfPoint ? this.lastestEndOfPoint.pointBalance : 0;
      // if (
      //     this.userPoints &&
      //     this.userPoints.pointPeriods &&
      //     this.userPoints.pointPeriods.length > 0
      // ) {
      //     return this.userPoints.pointPeriods[0].pointBalance;
      // } else {
      //     return 0;
      // }
    },
    displayPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    displayTransactions() {
      if (this.transactions.records && this.transactions.records.length > 0) {
        return this.transactions.records;
      } else {
        return [];
      }
    },
    lastestEndOfPoint() {
      if (
        this.userPoints &&
        this.userPoints.pointPeriods &&
        this.userPoints.pointPeriods.length > 0
      ) {
        let sorted = [...this.userPoints.pointPeriods];
        sorted.sort(function (a, b) {
          return a.endTs - b.endTs;
        });
        const latestEndOfPoint = sorted.find((p) => p.endTs >= moment().unix());
        return latestEndOfPoint;
      }
      return null;
    },
  },
  watch: {
    filterByType() {
      this.filterByTypeHandler();
    },
  },
  methods: {
    goBackTo() {
      let parent = this.$route.meta.parent || '/home';
      this.$router.push(parent);
    },
    pointClass(entryType) {
      return [
        'fL',
        'bold',
        { error: entryType == 'C' },
        { success: entryType == 'D' },
      ];
    },
    displaySignOfPoint(entryType) {
      return entryType == 'D' ? '+' : '-';
    },
    tsToDatetime(ts) {
      return moment(ts * 1000).format('YYYY/MM/DD HH:mm:ss');
    },
    tsToDate(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    getUserPoints() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
        headers: {},
      };

      return this.$http(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dummyTransactions() {
      let result = {
        total: 3,
        records: [
          {
            entryId: 144,
            pointAmount: 10,
            entryType: 'C',
            note: '花錢',
            transactionId: 74,
            transactionEntityId: 270,
            transactionTs: 1650021907,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 41,
          },
          {
            entryId: 146,
            pointAmount: 10,
            entryType: 'C',
            note: 'consumption',
            transactionId: 75,
            transactionEntityId: 270,
            transactionTs: 1650033376,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 42,
          },
          {
            entryId: 148,
            pointAmount: 10,
            entryType: 'C',
            note: 'consumption',
            transactionId: 76,
            transactionEntityId: 270,
            transactionTs: 1650033478,
            transactionType: 'Consumption-Discount',
            merchantOrderId: 43,
          },
        ],
      };
      let promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
      return promise;
    },
    callTransactionsAPI(start, end) {
      let endTime = end || Math.floor(Date.now() / 1000);
      let startTime = start || endTime - 1 * 60 * 60 * 24 * 30;

      var config = {
        method: 'get',
        url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points/${this.userPoints.pointCode}/ledgers?from_ts=${startTime}&to_ts=${endTime}`,
        headers: {},
      };
      return this.$http(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTransactions(start, end) {

      return (
        this.callTransactionsAPI(start, end)
          // return this.dummyTransactions()
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            console.log('err:', err);
          })
      );
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
    },
    filterByTypeHandler() {
      switch (this.filterByType) {
        case 'A':
          this.transactions = JSON.parse(
            JSON.stringify(this.originTransactions)
          );
          break;
        case 'C':
          this.transactions.records = this.originTransactions.records.filter(
            (t) => t.entryType === 'C'
          );
          break;
        case 'D':
          this.transactions.records = this.originTransactions.records.filter(
            (t) => t.entryType === 'D'
          );
          break;
        default:
          break;
      }
    },
    filterByTimeHandler() {
      function tdesc(b, a) {
        return a.transactionTs - b.transactionTs;
      }
      function tasc(a, b) {
        return a.transactionTs - b.transactionTs;
      }
      function pdesc(b, a) {
        return a.pointAmount - b.pointAmount;
      }
      function pasc(a, b) {
        return a.pointAmount - b.pointAmount;
      }
      switch (this.filterbyTime) {
        case 'tdesc':
          this.transactions.records.sort(tdesc);
          break;
        case 'tasc':
          this.transactions.records.sort(tasc);
          break;
        case 'pdesc':
          this.transactions.records.sort(pdesc);
          break;
        case 'pasc':
          this.transactions.records.sort(pasc);
          break;
        default:
          break;
      }
    },
    timeFilterConfirm() {
      if (this.timeFilterOption == 'Customize') {
        console.log(this.selectStart);
        this.selectStartErr = this.selectStart ? false : true;
        this.selectEndErr = this.selectEnd ? false : true;
        if (this.selectStartErr || this.selectEndErr) return;
      }
      this.changeTimeFilterValue();
      let start = this.timeFilterStart;
      let end = this.timeFilterEnd;
      this.getTransactions(start, end)
        .then((getTransactionsRes) => {
          this.transactions = JSON.parse(JSON.stringify(getTransactionsRes));
          this.originTransactions = JSON.parse(
            JSON.stringify(getTransactionsRes)
          );

          this.filterByTypeHandler();
        })
        .catch((err) => {
          console.log('err: ', err);
          this.$router.push('/error');
        });
    },
    changeTimeFilterOption(option) {
      this.timeFilterOption = option;
    },
    changeTimeFilterValue() {
      switch (this.timeFilterOption) {
        case 'Weekly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 7;
          this.displayedTimeFilterOption = '近一週';
          break;
        case 'Monthly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 30;
          this.displayedTimeFilterOption = '近一個月';
          break;
        case 'Yearly':
          this.timeFilterEnd = Math.floor(Date.now() / 1000);
          this.timeFilterStart = this.timeFilterEnd - 1 * 60 * 60 * 24 * 365;
          this.displayedTimeFilterOption = '近一年';
          break;
        case 'Customize':
          this.timeFilterEnd =
            this.dateToTs(this.selectEnd) > Date.now() / 1000
              ? Date.now() / 1000
              : this.dateToTs(this.selectEnd);
          this.timeFilterStart = this.dateToTs(this.selectStart);
          this.displayedTimeFilterOption = '自訂時間';
          break;
        default:
          break;
      }
    },
    dateToTs(date) {
      return new Date(date).getTime() / 1000;
    },
    selectStartChange() {
      this.selectStartErr = false;
    },
    selectEndChange() {
      this.selectEndErr = false;
    },
    changeTypeFilter(type) {
      this.filterByType = type;
    },
  },
};
</script>
